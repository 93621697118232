<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve">
        <title>Order_Active</title>
        <desc>Created with Sketch.</desc>
        <g id="Order_Active">
            <circle id="Oval" class="st0" :class="{active: active}" cx="40" cy="40" r="38.5"/>
            <path id="Shape" class="st1" :class="{active: active}" d="M19.2,25.5h23.6v17.7H19.2V25.5z M60.8,30.9l-8.2,8.8v-4.8h-4.8v-8h4.9V22L60.8,30.9z M21.7,55.3
                c-2.2,0-4.1-1.8-4.1-4s1.9-4,4.1-4s4.1,1.8,4.1,4C25.8,53.4,23.9,55.3,21.7,55.3z M30.9,55.3c-2.2,0-4.1-1.8-4.1-4s1.9-4,4.1-4
                s4.1,1.8,4.1,4C34.9,53.4,33.1,55.3,30.9,55.3z M49.2,55.3c-2.2,0-4.1-1.8-4.1-4s1.9-4,4.1-4s4.1,1.8,4.1,4
                C53.2,53.4,51.4,55.3,49.2,55.3z M58.4,55.3c-2.2,0-4.1-1.8-4.1-4s1.9-4,4.1-4s4.1,1.8,4.1,4C62.4,53.4,60.6,55.3,58.4,55.3z
                M40,55.3c-2.2,0-4.1-1.8-4.1-4s1.8-4,4.1-4c2.3,0,4.1,1.8,4.1,4C44.1,53.4,42.3,55.3,40,55.3z M21.7,49.6c-0.9,0-1.6,0.7-1.6,1.6
                s0.8,1.6,1.6,1.6c0.9,0,1.7-0.7,1.7-1.6C23.3,50.4,22.6,49.6,21.7,49.6z M58.3,49.6c-0.9,0-1.6,0.7-1.6,1.6s0.8,1.6,1.6,1.6
                c0.9,0,1.7-0.7,1.7-1.6S59.2,49.6,58.3,49.6z M49.2,49.6c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
                S50.1,49.6,49.2,49.6z M40,49.6c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6S40.9,49.6,40,49.6z M30.9,49.6
                c-0.9,0-1.6,0.7-1.6,1.6s0.8,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6S31.8,49.6,30.9,49.6z M16,46.4h48v-1.6H16V46.4z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'StepOne',
    props: {
        active: { type: Boolean, default: false }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../global-styles/variables';
.st0 {
    fill:none;
    stroke:#F8F9FA;
    stroke-width:3;

    &.active {
        stroke:$color-primary;
    }
}

.st1 {
    fill:#AEAEAE;

    &.active {
        fill:$color-primary;
    }
}
</style>