<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve">
        <title>Pickup_NonActive</title>
        <desc>Created with Sketch.</desc>
        <g id="Pickup_NonActive">
            <circle id="Oval" class="st0" :class="{active: active}" cx="40" cy="40" r="38.5"/>
            <polygon id="Fill-1" class="st1" :class="{active: active}" points="44.6,48.9 39.9,43.9 39.9,59.2 55.6,52.2 55.6,43.9 	"/>
            <polygon id="Fill-2" class="st1" :class="{active: active}" points="39.8,40.9 45,46.6 61.1,39.4 56.2,33.6 	"/>
            <polygon id="Fill-3" class="st1" :class="{active: active}" points="33.5,48.9 22.5,43.9 22.5,52.2 38.1,59.2 38.1,43.9 	"/>
            <polygon id="Fill-4" class="st1" :class="{active: active}" points="17,39.4 33.1,46.6 38.3,40.9 21.9,33.6 	"/>
            <polygon id="Fill-5" class="st1" :class="{active: active}" points="25,32.9 39.1,39.2 53.3,32.9 39.1,26.6 	"/>
            <polygon id="Fill-6" class="st1" :class="{active: active}" points="44.9,19.2 39.8,24.9 56.1,32.2 61.1,26.6 	"/>
            <polygon id="Fill-7" class="st1" :class="{active: active}" points="33.1,19.2 17,26.6 22,32.2 38.3,24.9 	"/>
            <circle class="st2" :class="{active: active}" cx="55.4" cy="53.4" r="7.1"/>
            <path class="st3" :class="{active: active}" d="M59.2,52c0,0.1,0,0.2-0.1,0.3l-3.9,3.9c-0.1,0.1-0.2,0.1-0.3,0.1s-0.2,0-0.3-0.1l-2.2-2.2
                c-0.1-0.1-0.1-0.2-0.1-0.3s0-0.2,0.1-0.3l0.6-0.6c0.1-0.1,0.2-0.1,0.3-0.1s0.2,0,0.3,0.1l1.3,1.3l2.9-2.9c0.1-0.1,0.2-0.1,0.3-0.1
                c0.1,0,0.2,0,0.3,0.1l0.6,0.6C59.2,51.7,59.2,51.8,59.2,52z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'StepOne',
    props: {
        active: { type: Boolean, default: false }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../global-styles/variables';

.st0 {
    fill:none;
    stroke:#F8F9FA;
    stroke-width:3;

    &.active {
        stroke:$color-primary;
    }
}

.st1 {
    fill-rule:evenodd;
    clip-rule:evenodd;
    fill:#AEAEAE;

    &.active {
        fill: $color-primary;
    }
}

.st2 {
    fill-rule:evenodd;
    clip-rule:evenodd;
    fill:#AEAEAE;
    stroke:#FFFFFF;

    &.active {
        fill: $color-primary;
    }
}

.st3 {
    fill:#FFFFFF;
}
</style>